.outer-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav {
    width: 100%;
    padding-top: 4px;
    height: 80px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    background-color: #192440;
}

.inner-container {
    width: 60vw;
    padding: 40px;
    border: 1px solid rgb(206, 205, 205);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.inner-container h1 {
    font-size: 30px;
    font-weight: bold;
}

.grid {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.container {
    border: 2px solid rgb(206, 205, 205);
    width: 35%;
    height: 180px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container:hover {
    border-color: #192440;
    cursor: pointer;
    transition: 300ms;
}

.selected {
    border: 3px solid #192440;
    background-color: rgb(225, 227, 228);
}

.continue-btn {
    margin-top: 60px;
    width: 300px;
    padding: 10px 50px;
    border-radius: 10px;
    color: white;
    background-color: #192440;
    display: flex;
    justify-content: center;
    border: none;
}

.continue-btn:hover {
    transform: translateY(-2px);
    transition: 300ms;
}

.input {
    display: none;
}

.container p {
    font-size: 18px;
    font-weight: bold;
}


/* for mobile */
@media screen and (max-width: 973px) {
    .inner-container h1 {
        font-size: 1.8rem;
    }

    .container p {
        font-size: 1rem;
    }
}
  
@media screen and (max-width: 901px) {
    .inner-container {
        width: 80vw;
    }

    .grid {
        flex-direction: column;
    }

    .container {
        width: 100%;
        height: 80px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 630px) {
    .inner-container h1 {
        font-size: 1.2rem;
    }

    .continue-btn {
        width: 80%;
    }
}