.signup_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .signup_innerBox {
    width: 100%;
    max-width: 468px;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .signup_error {
    color: red;
  }
  .signup_heading {
    font-size: 25px;
    font-weight: bold;
    @media screen and (max-width: 440px) {
      font-size: 20px;
    }
  }

  .signup_footer button {
    background-color: #192440;
    color: #fff;
    border: none;
    border-radius: 20px;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 16px;
    width: 100%;
    transition: 100ms;
    cursor: pointer;
  }
  
  .signup_footer button:hover {
    transform: translateY(-2px);
    transition: 300ms;
  }
  
  .signup_footer p span a {
    font-weight: bold;
    color: #192440 !important;
    letter-spacing: 1px;
    font-size: 1rem;
    text-decoration: none;
    @media screen and (max-width: 370px) {
      font-size: 13px;
    }
  }

  .signup_p {
    margin-top: 5px;
    color: #000;
    @media screen and (max-width: 370px) {
      font-size: 13px;
    }
  }
  

  /* mobile */
  @media screen and (max-width: 900px) {
    .signup_innerBox {
      width: 90%;
      max-width: 400px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .signup_innerBox {
      max-width: 450px;
    }
  }
  