@import 'bootstrap/dist/css/bootstrap.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}


