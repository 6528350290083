.srch-bar {
    display: flex;
    width: 100%;
    /* margin-bottom: 5%; */
}
    
.srch-input {
    flex: 1;
    padding-left: 30px;
    border-radius: 30px 30px 30px 30px;
    font-size: 16px;
    background-color: #efefef;

    @media screen and (max-width: 1000px) {
        height: 30px;
    }

    @media screen and (max-width: 654px) {
        height: 30px;
        font-size: 2.5vw;
    }

    @media screen and (max-width: 460px) {
        height: 20px;
    }
}
  
.srch-btn {
    padding: 10px 15px;
    background-color: #00FFAD;
    color: black;
    font-weight: bold;
    border: none;
    border-radius: 30px 30px 30px 30px;
    font-size: 16px;
    cursor: pointer;
    width: 100px;
    margin-left: 10px;

    &:hover {
        color: white;
        transition: 300ms;
    }

    @media screen and (max-width: 1000px) {
        width: 5rem;
        padding: 0.4rem 0.4rem;
        font-size: 0.8rem;
        justify-content: center;
    }

    @media screen and (max-width: 654px) {
        width: 10vw;
        font-size: 2vw;
    }

    @media screen and (max-width: 460px) {
        display: none;
    }
}

.srch-btn1 {
    color: #00FFAD;
    display: none;
    margin-left: 10px;
    /* border: 2px solid red; */
    height: 20px;

    @media screen and (max-width: 460px) {
        display: block;
    }
}