.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.login_innerBox {
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login_heading {
  font-size: 25px;
  font-weight: bold;
  @media screen and (max-width: 440px) {
    font-size: 20px;
  }
}

.login_footer button {
  background-color: #192440;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 16px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.login_footer button:hover {
  transform: translateY(-2px);
  transition: 300ms;
}

.login_footer p span a {
  font-weight: bold;
  color: #192440 !important;
  letter-spacing: 1px;
  font-size: 1rem;
  text-decoration: none;
  margin-left: 5px;
  @media screen and (max-width: 370px) {
    font-size: 13px;
  }
}

.login_error {
  color: red;
}

.login_method {
  display: flex;
  .btn {
    border: 1px solid black;
    margin-left: 3px;
  }
}

.login_p {
  display: flex;
  justify-content: center;
  color: #000;
  margin-top: 10px;
  @media screen and (max-width: 370px) {
    font-size: 13px;
  }
}

/* for mobile */
@media screen and (max-width: 900px) {
  .login_innerBox {
    width: 90%;
    max-width: 400px;
  }
}

@media screen and (max-width: 500px) {
  .login_innerBox {
    max-width: 450px;
  }

  .login_method {
    display: flex;
    flex-direction: column;
  
    .btn {
      border: 1px solid black;
      margin-left: 3px;
      margin-top: 5px;
    }
  }
}