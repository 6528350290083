.aboutUsWrapper {
    padding: 10px;
    background-color: white;
}

.aboutUsContainer {
    width: 60%;
    margin: 0 auto;
    padding: 10px;
    @media screen and (max-width: 1000px) {
        width: 70%;
    }
    @media screen and (max-width: 684px) {
        width: 90%;
    }
    @media screen and (max-width: 505px) {
        padding: 0;
        width: 100%;
    }
}

.aboutUsContainer h1 {
    font-weight: bolder;
}

.aboutUsContainer h3 {
    font-weight: bold;
}

.aboutUsContainer h6 {
    font-weight: bold;
}