.expert-list {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 10px; 
    justify-content: center; 
}

/* mobile */
@media screen and (max-width: 1058px) {
    .expert-list {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media screen and (max-width: 734px) {
    .expert-list {
        grid-template-columns: repeat(1, 1fr); 
    }
}