.expert-card {
  background-color: #e0fbf2;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (max-width: 380px) {
    font-size: 13px;
  }
}
  
.expert-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.hire-btn {
  padding: 10px 15px;
  background-color: #192440;
  color: #fff;
  border: none;
  border-radius: 30px 30px 30px 30px;
  font-size: 16px;
  cursor: pointer;
  width: 100px;

  @media screen and (max-width: 1000px) {
    padding: 5px 0;
    width: 80px;
    font-size: 14px;
  }
}

.hire-btn:hover {
  background-color: #192440;
  transform: translateY(-2px);
  transition: 300ms;
}