.outer-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/banner-bk.jpg);
}

.content {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    padding: 10px;
    gap: 15px;
}

.article-card {
    background-color: #e0fbf2;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    width: 90%;
    margin: auto;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.read-more-button {
    padding: 10px;
    background-color: #192440;
    color: #fff;
    border: none;
    border-radius: 30px 30px 30px 30px;
    font-size: 13px;
    cursor: pointer;
}

.center-text {
    text-align: center;
    color: white;
}

.item-title {
    text-transform: capitalize;
    font-weight: bold;
    @media screen and (max-width: 600px) {
        font-size: 1.1rem;
    }
}

.item-description {
    font-size: 15px;
}