.out-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(246, 246, 246);
}

.nav {
    width: 100%;
    height: 80px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    background-color: #192440;
}

.nav h1 {
    color: white;
}

.google-search-web {
    width: 50vw;
    display: flex; 
    align-items: center,;
    margin-left: 15%;

    @media screen and (max-width: 700px) {
        display: none;
    }
}

.google-search-mobile {
    width: 100%;
    display: none;

    @media screen and (max-width: 700px) {
        display: block;
    }
}

.out-list {
    list-style: none;
    padding: 10px;
    width: 80vw;
    height: 100vh;
    margin: auto;
    background-color: white;
    box-shadow: 5px 5px 10px rgb(222, 222, 222);
    overflow: auto;

    @media screen and (max-width: 700px) {
        width: 100vw;
    }
}

.in-list {
    display: flex;
    gap: 5px;
    flex-direction: column;
    padding-top: 12px;
}

.inner {
    display: flex;
    gap: 10px;
}

.snippet {
    font-size: 15px;
    width: 50rem;
    word-wrap: break-word;
}

.title {
    text-decoration: none;
    font-weight: bold;
    color: rgb(26, 23, 106);
}

.display-link {
    text-decoration: none;
    font-size: 14px;
    display: flex;
    gap: 5px;
    align-items: center;
    color: green;
}

/* .thumbnail {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-size: cover;
} */
