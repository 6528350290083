.container {
    min-height: 100vh;
    display: flex;
    background-color: rgb(232, 232, 232);
    justify-content: center;
}

.profileSection {
    margin-top: 90px;
    background-color: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 6px;
    width: 70%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 619px) {
        width: 100%;
        border-radius: 0px;
    }
}

.profileSection h4 {
    color: black;
    font-weight: bold;
}

.profilePicContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profilePicContainer input {
    display: none;
}

.profilePicContainer img {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    background-size: contain;
    cursor: pointer;
}

.userWrapper {
    padding: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.userDetails {
    width: 350px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    border-radius: 10px;
    background-color: rgb(242, 241, 241);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 411px) {
        width: 260px;
    }
}

.userDetails label {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    font-size: 15px;
}

.userDetails label input {
    width: 100%;
    height: 30px;
    font-size: 13px;
    background-color: transparent;
}

.saveButton {
    padding: 10px;
    display: flex;
    justify-content: center;
}

.saveButton button {
    padding: 5px 30px;
    border: none;
    color: white;
    background-color: #192440;
    border-radius: 8px;
}

.loader {
    display: flex;
    justify-content: center;
}