.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
}

h1 {
    text-align: center;
    margin-bottom: 30px;
}

.section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.row>div {
    flex: 1;
}

input, select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitButton {
    display: flex;
    justify-content: center;
}

.submitButton button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton button:hover {
    background-color: #0056b3;
}