.outer {
    height: 100vh;
}

.customer {
    padding: 7px;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.no-users {
    font-size: 35px;
    align-self: center;
    transform: translateY(70px);
}
.customer h1 {
    font-size: 30px;
    font-weight: bold;
    align-self: center;
}

.loader {
    margin-top: 20px;
    justify-content: center;
}