.outer {
    min-height: 100vh;
}

.expert {
    padding: 13px;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.expert h2 {
    align-self: center;
    @media screen and (max-width: 1000px) {
        font-size: 25px;
    }
}

.loader {
    margin-top: 20px;
    justify-content: center;
}

.no-expert {
    font-size: 30px;
    align-self: center;
    transform: translateY(70px);
}
