.search-bar {
  display: flex;
  width: 100%;
  margin-top: 10px;
}
  
.search-input {
  flex: 1;
  padding-left: 30px;
  border: 1px solid black;
  border-radius: 30px 30px 30px 30px;
  font-size: 16px;
  
  @media screen and (max-width: 1000px) {
    font-size: 14px;
    height: 35px;
  }

  @media screen and (max-width: 376px) {
    width: 70%;
  }
}

.search-btn {
  padding: 10px 15px;
  background-color: #192440;
  color: #fff;
  border: none;
  border-radius: 30px 30px 30px 30px;
  font-size: 16px;
  cursor: pointer;
  width: 100px;
  margin-left: 10px;

  @media screen and (max-width: 1000px) {
    padding: 0;
    width: 80px;
    font-size: 14px;
  }
}

.search-btn:hover {
  background-color: #192440;
  transform: translateY(-2px);
  transition: 300ms;
}