.customer-card {
  background-color: #e0fbf2;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
}

.customer-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}